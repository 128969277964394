import { getLoginQrcode, qrcodeLoginRes } from '@/api/user'
import { v4 as uuidv4 } from 'uuid';
import qrcode from 'qrcode'
import { ref } from 'vue';
import { useMessage } from 'naive-ui';

export default function useQrcodeLogin(gid: string, successCallback: (res: any) => void) {
  let uuid = ''

  const qrcodeUrl = ref('');

  const initQrcode = async () => {
    // 初始化二维码的uuid
    uuid = uuidv4();
    isExpired.value = false;
    // 获取二维码的数据
    const { data } = await getLoginQrcode({ code: uuid, gid });

    if (data) {
      // 生成二维码
      qrcode.toDataURL(data.url, (err: any, url: string) => {
        if (err) return console.log(err);
        qrcodeUrl.value = url
      });
      // 轮询二维码结果
      loopQrcodeResult()
      // 开始过期计时
      expireTimeOut()
    }
  }

  let timer: NodeJS.Timeout;
  const loopQrcodeResult = async () => {
    const { data, code, message } = await qrcodeLoginRes({ code: uuid });
    if (code === 27) { // 继续轮询
      timer = setTimeout(loopQrcodeResult, 3000); // 3s一次
      return;
    }
    if (code === 26) { // 二维码过期，重新获取
      initQrcode()
      return;
    }
    if (code !== 0) { // 未知错误码，提示报错
      const Message = useMessage();
      Message.error(message)
      return;
    }
    successCallback(data);
  }

  const expireTime = 60000; // 60s
  const isExpired = ref(false);
  let timer2: NodeJS.Timeout;
  const expireTimeOut = () => {
    timer2 = setTimeout(() => {
      isExpired.value = true;
      destroyQrcode()
    }, expireTime)
  }

  const destroyQrcode = () => {
    clearTimeout(timer)
    clearTimeout(timer2)
  };

  return {
    qrcodeUrl,
    initQrcode,
    isExpired,
    destroyQrcode
  }
}