<template lang="pug">
#typeIt
  .msg(ref="text")
  .msg(ref="text2" v-show="showParagraph2")
  .msg(ref="text3" v-show="showParagraph3")
</template>

<script setup>
import { ref, onMounted } from "vue";
import TypeIt from 'typeit'
const showParagraph2 = ref(false)
const showParagraph3 = ref(false)
const text = ref(null)
const text2 = ref(null)
const text3 = ref(null)
onMounted(() => {
  let options = {
    root: null,
    rootMargin: '0px',
    threshold: [0]
  }
  let showNums = 0
  let callback = function (entries, observer) {
    if (entries[0].intersectionRatio > 0 && showNums === 0) {
      showNums++
      typeitFunc()
    }
  };
  let typeItEl = document.querySelector(".my_resume");
  let observer = new IntersectionObserver(callback, options);
  observer.observe(typeItEl);
})
const typeitFunc = () => {
  new TypeIt(text.value, {
    strings: [
      "核心数据驱动：基于多维度数据洞察，重新构建运营策略，实现用户日活增长20%，并稳定月留存率于85%以上。",
    ],
    cursorChar: "<span class='cursorChar'>|<span>",//用于光标的字符。HTML也可以
    speed: 30,
    lifeLike: true,// 使打字速度不规则
    breakLines: true,// 控制是将多个字符串打印在彼此之上，还是删除这些字符串并相互替换
    loop: false,//是否循环
    afterComplete: function (instance) {
      instance.destroy();
      showParagraph2.value = true
    }
  }).go()
  new TypeIt(text2.value, {
    strings: [
      "与产品部门深度合作：围绕1000+用户反馈，针对性优化产品，协同产品团队发布10+版本更新，95%用户表示满意。"
    ],
    cursorChar: "<span class='cursorChar'>|<span>",//用于光标的字符。HTML也可以
    speed: 30,
    startDelay: 3500,
    lifeLike: true,// 使打字速度不规则
    breakLines: true,// 控制是将多个字符串打印在彼此之上，还是删除这些字符串并相互替换
    loop: false,//是否循环
    afterComplete: function (instance) {
      instance.destroy();
      showParagraph3.value = true
    }
  }).go()

  new TypeIt(text3.value, {
    strings: [
      "推广活动取得突破：牵头策划5场重点线上活动，单场活动日均拉新超过20万，累计吸引新用户达100万，与去年同期相比增长30%。"
    ],
    cursorChar: "<span class='cursorChar'>|<span>",//用于光标的字符。HTML也可以
    speed: 30,
    startDelay: 7000,
    lifeLike: true,// 使打字速度不规则
    breakLines: true,// 控制是将多个字符串打印在彼此之上，还是删除这些字符串并相互替换
    loop: false,//是否循环
  }).go()
}
</script>

<style scoped lang="sass">
.msg
    color: #333333
    letter-spacing: 2px
    font-size: 16px
    position: relative
    padding-left: 16px
    margin-bottom: 26px
    &::after
        content: ''
        display: block
        width: 6px !important
        height: 6px !important
        background: #333333
        border-radius: 15px
        position: absolute
        left: 0px
        top: 8px
.msg :deep(.cursorChar)
    display: inline-block
    margin-left: 2px
</style>
