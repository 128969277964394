<template>
  <n-popover ref="popoverRef" trigger="hover" style="margin-top: 26px;box-shadow: none;" placement="bottom-end"
    :show-arrow="false" raw>
    <div class="vip-card" :style="themeStyle">
      <div class="vip-card-header">
        <div class="icon-box">
          <svg-icon class="icon" name="vip"></svg-icon>
        </div>
        <div class="info">
          <div class="vip-name" v-if="userStore.isVip">{{ userStore.userTypeName }}</div>
          <div class="vip-time" v-if="userInfo.expiry_time">会员有限期至：{{ userInfo.expiry_time }} </div>
        </div>
      </div>
      <div class="vip-card-content">
        <div class="vip-services">
          <div class="vip-services-item">
            <div class="icon-box" style="font-size: 41px;">
              <svg-icon name="vip-service-1"></svg-icon>
            </div>
            千亿级大模型
          </div>
          <div class="vip-services-item">
            <div class="icon-box" style="font-size: 32px;">
              <svg-icon name="vip-service-2"></svg-icon>
            </div>
            简历模版
          </div>
          <div class="vip-services-item">
            <div class="icon-box" style="font-size: 32px;">
              <svg-icon name="vip-service-3"></svg-icon>
            </div>
            面试助手
          </div>
          <div class="vip-services-item">
            <div class="icon-box" style="font-size: 33px;">
              <svg-icon name="vip-service-4"></svg-icon>
            </div>
            无限次下载
          </div>
          <div class="vip-services-item">
            <div class="icon-box" style="font-size: 35px;">
              <svg-icon name="vip-service-5"></svg-icon>
            </div>
            邮件发送
          </div>
        </div>
      </div>
      <div class="vip-card-footer" @click="onOpenVipDialog" v-if="userStore.isRenewable">
        <div class="btn">立即续费</div>
      </div>
    </div>
    <template #trigger>
      <slot></slot>
    </template>
  </n-popover>
</template>

<script lang="ts" setup>
import { NPopover } from 'naive-ui';
import { useGlobalStore } from '@/store/useGlobalStore'
import { useUserState } from '@/store/useUserStore';
import { computed, ref } from 'vue';
const popoverRef = ref<typeof NPopover>()
const globalStore = useGlobalStore()
const onOpenVipDialog = () => {
  globalStore.setShowVipDialog(true)
  popoverRef.value?.setShow(false);
}

const userStore = useUserState();
const userInfo = computed(() => {
  return userStore.user_info
})
const themeStyle = computed(() => {
  if (userStore.isGroup) {
    return {
      '--vip-card-bg-color': '#EFF3FF',
      '--vip-card-color': 'var(--primary)'
    }
  } else {
    return {
      '--vip-card-bg-color': '#FFF5F0',
      '--vip-card-color': '#F65907'
    }
  }
})
</script>

<style lang="scss" scoped>
.vip-card {
  border-radius: 14px;
  width: 527px;
  background: #FFFFFF;
  overflow: hidden;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  --vip-card-bg-color: #EFF3FF;
  --vip-card-color: var(--primary);

  .vip-card-header {
    height: 100px;
    display: flex;
    align-items: center;
    padding: 22px 40px;
    background-color: var(--vip-card-bg-color);

    .icon-box {
      width: 55px;
      height: 55px;
      border-radius: 50%;
      background-color: #fff;
      margin-right: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 30px;
      color: var(--vip-card-color);

      .icon {
        margin-top: 2px;
        margin-left: 3.5px;
      }
    }

    .info {
      flex: 1;

      .vip-name {
        font-size: 18px;
        line-height: 24px;
      }

      .vip-time {
        margin-top: 5px;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }

  .vip-card-content {
    padding: 56px 0 48px;

    .vip-services {
      display: flex;
      justify-content: space-between;
      padding: 0 50px;

      .vip-services-item {
        width: 56px;
        font-size: 16px;
        line-height: 22px;
        white-space: nowrap;
        display: flex;
        flex-direction: column;
        align-items: center;

        .icon-box {
          width: 56px;
          height: 56px;
          border-radius: 8px;
          background-color: var(--vip-card-bg-color);
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--vip-card-color);
          margin-bottom: 7px;
        }
      }
    }
  }

  .vip-card-footer {
    padding-bottom: 24px;
    display: flex;
    justify-content: center;

    .btn {
      cursor: pointer;
      height: 35px;
      border-radius: 3px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      color: var(--vip-card-color);
      background: linear-gradient(270deg, var(--vip-card-color) -500%, var(--vip-card-bg-color) 100%);
    }
  }
}
</style>