<template>
  <div class="login-form">
    <div class="login-form-t">验证码登录</div>
    <div class="login-input">
      <div class="label-86">+86</div>
      <div class="input-box">
        <input type="text" placeholder='请输入手机号码' maxlength='11' v-model="phoneNo">
      </div>
    </div>
    <div class="login-input login-input-verify">
      <div class="input-box">
        <input type="text" class='verifyInput' placeholder='请输入验证码' maxlength='6' v-model="verifyCode">
      </div>
      <n-button class='verify-btn' id='verify_button' text :disabled="smsBtnDisable">
        {{ smsBtnTxt }}
      </n-button>
    </div>
    <div id="captcha-element"></div>
    <n-button class="login-form-btn" type="primary" @click="onLogin">
      登 录
    </n-button>
  </div>
</template>

<script lang="ts" setup>
import { postSendCode, postLogin } from '@/api/user'
import { ref } from 'vue';
import { NButton, useMessage } from 'naive-ui'
import { useGlobalStore } from '@/store/useGlobalStore'; 
import { ReqLoginParams } from '@/common/api/user/interface';

const globalStore = useGlobalStore()

const props = defineProps<{
  isAgree: boolean
}>()
const emit = defineEmits<{
  (e: 'loginSuccess', res: any): void
}>()

const phoneNo = ref('')
const verifyCode = ref('')
const smsBtnTxt = ref('发送验证码')
const wait = ref(60)
const smsBtnDisable = ref(false)
const sendCode = async (captchaVerifyParam: string) => {
  const { data } = await postSendCode({
    captchaVerifyParam: captchaVerifyParam,
    phone: phoneNo.value
  })
  return data
}

let _timer: NodeJS.Timeout;
const timeCycel = () => {
  clearTimeout(_timer);
  if (wait.value <= 0) {
    smsBtnTxt.value = '重新获取'
    smsBtnDisable.value = false;
  } else {
    smsBtnDisable.value = true;
    smsBtnTxt.value = wait.value + 's'
  }
  _timer = setTimeout(() => {
    wait.value -= 1;
    if (wait.value >= 0) {
      timeCycel();
    }
  }, 1000)
}
const resetTime = () => {
  clearTimeout(_timer);
  smsBtnTxt.value = '获取验证码'
  smsBtnDisable.value = false;
  wait.value = 60;
}
// 业务请求(带验证码校验)回调函数
/**
 * @name captchaVerifyCallback
 * @function
 * 请求参数：由验证码脚本回调的验证参数，不需要做任何处理，直接传给服务端即可
 * @params {string} captchaVerifyParam
 * 返回参数：字段名固定，captchaResult为必选；如无业务验证场景时，bizResult为可选
 * @returns {{captchaResult: boolean, bizResult?: boolean|undefined}} 
 */
async function captchaVerifyCallback(captchaVerifyParam: any) {
  // console.log('captchaVerifyParam: ', captchaVerifyParam);
  if (phoneNo.value === '') {
    message.info("请输入手机号")
    return
  } else if (!(/^1\d{10}$/.test(phoneNo.value)) || phoneNo.value.length !== 11) {
    message.info("请输入正确的手机号")
    return
  }
  resetTime();
  timeCycel();
  // 1.向后端发起业务请求，获取验证码验证结果和业务结果
  const result: any = await sendCode(captchaVerifyParam)
  // console.log('result123: ', result);
  if (!result.captchaVerifyResult) {
    resetTime()
  }

  // // 2.构造标准返回参数
  const verifyResult = {
    captchaResult: result.captchaVerifyResult, // 验证码验证是否通过，boolean类型，必选
    bizResult: result.captchaVerifyResult //从result获取您的业务验证结果, // 业务验证是否通过，boolean类型，可选；若为无业务验证结果的场景，bizResult可以为空
  };
  return verifyResult;
}
// 业务请求验证结果回调函数
function onBizResultCallback(bizResult: any) {
  // console.log('bizResult: ', bizResult);
}

let captcha = ''
const initCaptcha = () => {
  // 嵌入式
  // @ts-ignore
  initAliyunCaptcha({
    SceneId: 'onkyf09b', // 场景ID。通过步骤一添加验证场景后，您可以在验证码场景列表，获取该场景的场景ID
    prefix: 'ty6pca', // 身份标。开通阿里云验证码2.0后，您可以在控制台概览页面的实例基本信息卡片区域，获取身份标
    mode: 'popup', // 验证码模式。embed表示要集成的验证码模式为嵌入式。无需修改
    element: '#captcha-element', // 页面上预留的渲染验证码的元素，与原代码中预留的页面元素保持一致。
    button: '#verify_button', // 触发业务请求的元素。button表示单击登录按钮后，触发captchaVerifyCallback函数。您可以根据实际使用的元素修改element的值
    captchaVerifyCallback: captchaVerifyCallback, // 业务请求(带验证码校验)回调函数，无需修改
    onBizResultCallback: onBizResultCallback, // 业务请求结果回调函数，无需修改
    getInstance: getInstance, // 绑定验证码实例函数，无需修改
    slideStyle: {
      width: 360,
      height: 40,
    }, // 滑块验证码样式，支持自定义宽度和高度，单位为px。其中，width最小值为320 px
    language: 'cn', // 验证码语言类型，支持简体中文（cn）、繁体中文（tw）、英文（en）
    immediate: false, // 完成验证后，是否立即发送验证请求（调用captchaVerifyCallback函数）
  });
  function getInstance(instance: any) {
    captcha = instance;
  }
}
defineExpose({
  initCaptcha
})

const message = useMessage();
const onLogin = async () => {
  if (phoneNo.value === '') {
    message.info("请输入手机号")
    return
  } else if (!(/^1\d{10}$/.test(phoneNo.value)) || phoneNo.value.length !== 11) {
    message.info("请输入正确的手机号")
    return
  } else if (verifyCode.value === '') {
    message.info("请输入验证码")
    return
  }
  if (!props.isAgree) {
    message.info("请阅读并同意《用户服务协议》和《隐私协议》")
    return
  }
  
  console.log('globalStore.gid', globalStore.gid)
  const params:ReqLoginParams = {
    phone: phoneNo.value,
    sms_code: Number(verifyCode.value),
  }
  if (globalStore.gid) {
    params.group_id = globalStore.gid
  }
  const { data: res } = await postLogin(params)
  if (res) {
    emit('loginSuccess', res);
  }
}
</script>

<style lang="scss" scoped>
.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 396px;

  .login-form-t {
    font-size: 24px;
    font-weight: bold;
    line-height: 32px;
    margin-bottom: 32px;
  }

  .login-input {
    width: 286px;
    height: 52px;
    background: #F2F4F9;
    border-radius: 3px;
    display: flex;
    align-items: center;

    &+.login-input {
      margin-top: 14px;
    }

    .input-box {
      flex: 1;
      overflow: hidden;
    }

    input {
      width: 100%;
      background: transparent;
      font-size: 16px;
    }

    .label-86 {
      font-size: 16px;
      padding: 0 20px;
      height: 22px;
      line-height: 22px;
      border-right: 1px solid #CCCCCC;
      margin-right: 24px;
    }

    &.login-input-verify {
      display: flex;
      justify-content: space-between;
      padding: 0 16px 0 20px;

      .verify-btn {
        font-size: 16px;
        color: var(--primary);
      }
    }
  }

  .login-form-btn {
    margin-top: 50px;
    width: 286px;
    height: 40px;
    font-size: 16px;
    border-radius: 3px;

    :deep(.n-button__content) {
      font-weight: bold;
    }
  }
}
</style>