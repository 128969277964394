import { defineStore } from "pinia";
import router from '@/router'
import { isMobileView } from '@/utils/index'
const isMobile = isMobileView();

export const useGlobalStore = defineStore("global", {
  state: () => {
    return {
      gid: window._future_gid,
      showLogin: false,
      afterLoginPath: '',
      
      showVipDialog: false,
      companyConfig: {
        "host": "dev.futurecv.cn",
        "title": "未来简历-HR推荐的专业AI简历生成工具、轻松搞定专业简历",  // 网站的title
        "icon_logo_url": "https://file.futurecv.cn/static/company/0ef5ce8a-0388-4822-b420-e838c5f3f7c0.png",  // 标签上的icon 地址
        "light_logo_url": "https://file.futurecv.cn/static/company/cc2b4518-1bbe-4ba7-9865-c08bce9e9fb1.png", // 白色的logo 登录页使用
        "black_logo_url": "https://file.futurecv.cn/static/company/6685d9c8-a6ef-42a2-b025-c4f162e492c2.png",  // 深色的logo   首页左上角
        "customer_url": "https://file.futurecv.cn/static/company/eda0b8ad-87aa-4e6f-b5a0-00da51988523.png",  // 客服的二维码
        "show_name": "简历为王",  // 就是logo 右边那个文字，有的可能为空(logo 本身携带 或者是长的logo)
        "wechat_login": true, // 打开微信登录
        "mobile_login": true,  // 打开手机号登录
        "hide_footer": true // 底部是否隐藏
      }
    };
  },
  actions: {
    setCompanyConfig(value: any) {
      document.title = value.title
      document.querySelectorAll('[rel="icon"]')[0].href = value.icon_logo_url
      this.companyConfig = value
    },
    setShowLogin(value: boolean) {
      if (this.gid) {
        if (value) {
          router.push('/group-login')
        }
      } else {
        if (isMobile) {
          router.push('/m-login')
        } else {
          this.showLogin = value
        }
      }
    },
    setAfterLoginPath(path: string) {
      this.afterLoginPath = path
    },

    setShowVipDialog(val: boolean) {
      this.showVipDialog = val
    }
  },
});