const env = import.meta.env || {}
const { VITE_mock_localStorge, VITE_micro_switch, DEV, VITE_trace_id } = env;

const isDev = DEV
const isOpenMockLocalStorage = VITE_mock_localStorge === 'on';
const isOpenMicro = VITE_micro_switch === 'on';
const isOpenTraceId = VITE_trace_id === 'on';
const lanhuTokenName = 'token';
const loginAllMsg = 'loginAllMsg';

export { 
    env,
    isDev,
    isOpenMockLocalStorage,
    isOpenMicro,
    isOpenTraceId,
    lanhuTokenName,
    loginAllMsg
}