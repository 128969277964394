import { createRouter, createWebHistory } from "vue-router";
import Home from '@/views/home/index.vue';
import MHome from '@/views/home/mobile.vue';
import { isMobileView } from '@/utils/index'
const isMobile = isMobileView();

const routes = [
  {
    path: '/',
    component: isMobile ? MHome : Home,
    meta: { title: '未来简历' }
  },
  {
    path: '/group-login',
    component: () => import('@/views/groupLogin/index.vue'),
    meta: { title: '未来简历-登录' }
  },
  {
    path: '/token-login',  // 第三方的跳转登录
    component: () => import('@/views/thirdLogin/index.vue'),
    meta: { title: '跳转登录' }
  },
  {
    path: '/m-login',
    component: () => import('@/components/layout.vue'),
    meta: { title: '未来简历-登录' },
    children: [
      {
        path: '',
        component: () => import('@/views/mobileLogin/index.vue'),
      }
    ]
  },
  {
    path: '/my-resume',
    component: () => import('@/components/layout.vue'),
    meta: { title: '未来简历-我的简历' },
    children: [
      {
        path: '',
        component: () => import('@/views/myResume/index.vue'),
      }
    ]
  },
  {
    path: '/template-center',
    component: () => import('@/components/layout.vue'),
    meta: { title: '未来简历-精品模版库' },
    children: [
      {
        path: '',
        component: isMobile
          ? () => import('@/views/templateCenter/mobile.vue')
          : () => import('@/views/templateCenter/index.vue'),
      }
    ]
  },
  {
    path: '/create-resume',
    component: () => import('@/components/layout.vue'),
    meta: { title: '未来简历-创建简历' },
    children: [
      {
        path: '',
        component: () => import('@/views/createResume/index.vue'),
      }
    ]
  },
  {
    path: '/edit-resume',
    meta: { title: '未来简历-编辑简历' },
    component: () => import('@/views/editResume/index.vue'),
  },
  {
    path: '/user-privacy-terms',
    meta: { title: '未来简历-用户隐私条款'},
    component: () => import('@/components/UserPrivacyTerms.vue'),
  },
  {
    path: '/service-agreement',
    meta: { title: '未来简历-服务协议'},
    component: () => import('@/components/ServiceAgreement.vue'),
  },
  {
    path: '/interview-helper',
    component: () => import('@/components/layout.vue'),
    meta: { title: '未来简历-面试助手' },
    children: [
      {
        path: '',
        component: () => import('@/views/interviewHelper/index.vue'),
      }
    ]
  },
  {
    path: '/my-order',
    component: () => import('@/components/layout.vue'),
    meta: { title: '未来简历-我的订单' },
    children: [
      {
        path: '',
        component: () => import('@/views/myOrder/index.vue'),
      }
    ]
  },
  {
    path: '/user-center',
    component: () => import('@/components/layout.vue'),
    meta: { title: '未来简历-个人主页' },
    children: [
      {
        path: '',
        component: () => import('@/views/userCenter/index.vue'),
      }
    ]
  },
  {
    path: '/about-us',
    component: () => import('@/components/layout.vue'),
    meta: { title: '未来简历-关于我们' },
    children: [
      {
        path: '',
        component: () => import('@/views/aboutUs/index.vue'),
      }
    ]
  },
  {
    path: '/interview',
    meta: { title: '未来简历-模拟面试' },
    component: () => import('@/components/layout.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/interview/index.vue'),
      }
    ]
  },
  {
    path: '/!/:chapters*',
    redirect: (to: any) => {
      // console.log('to: ', to);
      let path = to.path;
      if (path.substr(0, 3) === '/!/') {
        path = path.slice(2, path.length);
      }
      return { path };
    },
  }
]

const base = (function () {
  const pathArr = window.location.pathname.split('/');
  if (pathArr[1]) {
    const basePath = '/' + pathArr[1];
    const isPage = routes.some(v => v.path === basePath);
    if (isPage) {
      return '/'
    } else {
      window._future_gid = pathArr[1];
      return basePath
    }
  } else {
    return '/'
  }
})();

const router = createRouter({
  history: createWebHistory(base),
  routes
});

export default router