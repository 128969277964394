import request from './axios'

interface TOrderDetail {
  url: string
  order_id: string
}

export enum PAY_STATUS {
  'INIT' = 'INIT', // 支付中
  'SUCCESS' = 'SUCCESS', // 支付成功
  'INVALID' = 'INVALID', // 过期
}

// 获取套餐信息
export const getVipMenus = request<any, { menus: TVipMenu[] }>('/api/user/pay/vip_menus', 'GET')

// 创建套餐订单
export const createMenusOrder = request<Pick<TVipMenu, 'menu_id'>, TOrderDetail>('/api/user/pay/order', 'POST')

// 查询订单支付状态
export const getPayStatus = request<Pick<TOrderDetail, 'order_id'> & { forced?: boolean }, { status: PAY_STATUS }>('/api/user/pay/status', 'GET')

// 兑换码
export const exchangeCdKey = request<{ cd_key: string }, { status: PAY_STATUS }>('/api/user/pay/cd_key', 'POST')

// 查询用户订单
export const userOrder = request<any, { orders: TOrderInfo[] }>('/api/user/pay/user_order', 'GET')

