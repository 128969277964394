<template lang="pug">
img(class='img_icon' :src="src")
</template>

<script lang="ts" setup>
import { computed } from 'vue'
const props = defineProps({
    srcImgName: {
        type: String,
        default: 'yin'
    },
    width: {
        type: String,
        default: '0'
    },
    height: {
        type: String,
        default: '0'
    },
})
const src = computed(()=>{
    return new URL(`../../../src/assets/img/${props.srcImgName}.png`,import.meta.url).href
})
const widthInPx = computed(()=>{
    return `${props.width}px`
})
const heightInPx = computed(()=>{
    return `${props.height}px`
})
</script>

<style scoped lang="sass">
.img_icon
    width: v-bind(widthInPx)
    height: v-bind(heightInPx)
</style>
