import localforage from "localforage";

export default function forageInit() {
  localforage.config({
        driver      : localforage.INDEXEDDB, // Force WebSQL; same as using setDriver()
        name        : 'qyj-passport',
        version     : 1.0,
        size        : 4980736, // Size of database, in bytes. WebSQL-only for now.
        storeName   : 'qyj-passport-local', // Should be alphanumeric, with underscores.
        description : 'manage qyj-passport local info'
    });
}