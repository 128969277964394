<template>
  <div class="vip-btn" :class="btnClass">
    <svg-icon class="vip-btn--icon" name="crown" />
    <slot></slot>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps<{
  type: 'primary' | 'gold'
}>()

const btnClass = computed(() => {
  return 'vip-btn-' + props.type
})
</script>

<style lang="scss" scoped>
.vip-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  padding: 0 10px;
  font-size: 12px;
  border-radius: 3px;
  cursor: pointer;

  .vip-btn--icon {
    margin-right: 6px;
  }

  &.vip-btn-primary {
    color: var(--primary);
    border: 1px solid var(--primary);
  }

  &.vip-btn-gold {
    color: #C36D21;
    background: linear-gradient(270deg, #F1DECC 0%, #FFF8EE 100%);
  }
}
</style>