import { defineStore } from "pinia";
import { getUserInfoLogin, logout } from '@/api/user'
import type { UserInfo } from './types';
import { getStorage, setStorage } from '@/utils/storage'
import router from '@/router'
import { useGlobalStore } from "./useGlobalStore";

const userTypeEnum = [
  undefined,
  '普通用户',
  '月卡会员',
  '团体会员',
  '年卡会员',
  '终生会员'
]
export const useUserState = defineStore("user", {
  state: () => {
    return {
      token: getStorage('token') || '',
      user_info: {} as UserInfo
    };
  },
  getters: {
    isNormal(state) {
      return ![2, 3, 4, 5].includes(state.user_info.user_type)
    },
    isVip(state) {
      return [2, 3, 4, 5].includes(state.user_info.user_type)
    },
    isGroup(state) {
      return state.user_info.user_type === 3
    },
    isLifetime(state) {
      return state.user_info.user_type === 5
    },
    isRenewable(state) { // 是否为可续费会员
      return [2, 4].includes(state.user_info.user_type)
    },
    userTypeName(state) {
      return userTypeEnum[state.user_info.user_type] || '普通用户'
    },
  },
  actions: {
    // 获取用户信息
    async getUserInfo() {
      const { data } = await getUserInfoLogin({})
      // console.log('刷新用户信息: ', data);
      this.setUserInfo(data)
    },
    setToken(value: string) {
      setStorage('token', value);
      this.token = value
    },
    setUserInfo(value: any) {
      this.user_info = value
    },
    logout(relogin?: boolean) {
      try {
        // todo: 退出登录接口会报错，待修复
        logout({})
      } catch (e) {
        console.log(e)
      }
      // 不管退出登录接口是否成功，都清空用户数据重新登陆
      localStorage.clear()
      this.setUserInfo({})
      this.setToken('');
      router.replace('/');
      if (relogin) {
        setTimeout(() => {
          const globalStore = useGlobalStore();
          globalStore.setShowLogin(true)
        })
      }
    }
  },
});
