<template>
  <div class="m-main">
    <frHead />
    <!-- 第一部分 -->
    <div class="banner">
      <div class="txt">🎉 开启未来，从简历开始</div>
      <div class="tit" ref="textEl"></div>
      <div class="des">超越简历专家，让你的简历通过率提高300%！</div>
      <n-button class="link-btn" size="small" type="primary" @click="goTemplate">免费定制专业简历</n-button>
      <div class="logos">
        <div class="des2">受到全球 300 万成功求职者的信赖</div>
        <div class="group">
          <img class="company-logo" src="@/assets/img/company/google.svg" />
          <img class="company-logo" src="@/assets/img/company/huawei.svg" style="height: 32px" />
          <img class="company-logo" src="@/assets/img/company/bytedance.svg" />
          <img class="company-logo" src="@/assets/img/company/cmb.svg" />
          <img class="company-logo" src="@/assets/img/company/lixiang.svg" style="height: 8px" />
        </div>
      </div>
    </div>
    <div class="tip-icon">
      <!-- <svg-icon name="arrow-down-cir"></svg-icon> -->
    </div>
    <!-- 第二部分 -->
    <div class="block my_resume">
      <div class="b-title">
        <span @click="goTemplate">魔法一般、自动构建专业求职简历</span>
      </div>
      <div class="card">
        <div class="resume-tit">
          <span>未来简历科技有限公司</span>
          <span>产品运营</span>
          <span>2018.7 - 2023.8</span>
        </div>
        <typeit />
      </div>
    </div>
    <!-- 第三部分 -->
    <div class="block resume-template">
      <div class="b-title">
        <span @click="goTemplate">外观精美，ATS 友好型模板</span>
      </div>
      <div class="template-ctx">
        <n-carousel effect="card" prev-slide-style="transform: translateX(-190%) translateZ(-400px);"
          next-slide-style="transform: translateX(90%) translateZ(-400px);" style="height:207px;padding-bottom: 45px;"
          show-dots autoplay :interval="1000">
          <n-carousel-item :style="{ width: '104px' }">
            <img class="carousel-img" src="@/assets/img/resume3.png" />
          </n-carousel-item>
          <n-carousel-item :style="{ width: '104px' }">
            <img class="carousel-img" src="@/assets/img/resume2.png" />
          </n-carousel-item>
          <n-carousel-item :style="{ width: '104px' }">
            <img class="carousel-img" src="@/assets/img/resume4.png" />
          </n-carousel-item>
        </n-carousel>
      </div>
    </div>
    <!-- 第四部分 -->
    <div class="block interview-assistant">
      <div class="b-title">
        <span @click="goHelper">面试预测助手</span>
      </div>
      <div class="card answer_box">
        <typeitAnswer />
      </div>
    </div>
    <!-- 第五部分 -->
    <div class="block four-block">
      <div class="tip">用户评论</div>
      <div class="b-title">受到超过3,000,000用户的信赖</div>
      <div class="four-box">
        <div class="box">
          <img class="icon" src="@/assets/img/yin.png">
          <div class="txt">我用过去的简历在网上投递，几乎没什么面试机会。但自从用了未来简历，仅一个月，我投的职位中有近半都回应了我。感激不尽！”</div>
          <div class="partner">
            <imgIcon width="57" height="20" srcImgName="gg"></imgIcon>
            <div class="name">
              <span class="bold">David</span> 产品经理
            </div>
          </div>
        </div>
        <div class="box">
          <img class="icon" src="@/assets/img/yin.png">
          <div class="txt">我大学毕业后寻找第一份工作时，未来简历给了我所需的优势。一份好的简历会收到很多面试邀请!我强烈推荐它。出色的设计，非常易于使用，完全值得购买。”</div>
          <div class="partner">
            <imgIcon width="48.8" height="16" srcImgName="pg"></imgIcon>
            <div class="name" style="margin-top: 4.5px">
              <span class="bold">John</span> 快销管培生
            </div>
          </div>
        </div>
        <div class="box">
          <img class="icon" src="@/assets/img/yin.png">
          <div class="txt">当我换工作时，我想要一份具有现代外观和感觉的简历，这将使我与其他申请者区分开来。当我遇到未来简历时，我非常喜欢他们的设计和易用性。更重要的是，面试官告诉我他们对我简历的非常满意。”
          </div>
          <div class="partner">
            <imgIcon width="48.8" height="16" srcImgName="wem"></imgIcon>
            <div class="name" style="margin-top: 4.5px">
              <span class="bold">Peter</span> 市场营销
            </div>
          </div>
        </div>
        <div class="box">
          <img class="icon" src="@/assets/img/yin.png">
          <div class="txt">未来简历不仅提供模板，更像一个资深HR，手把手的帮你修改简历。我强烈向每一位求职者推荐未来简历。”</div>
          <div class="partner">
            <imgIcon width="48.8" height="16" srcImgName="hw"></imgIcon>
            <div class="name" style="margin-top: 4.5px">
              <span class="bold">苏** </span> 嵌入式开发
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 第六部分 -->
    <div class="block adv-box">
      <div class="b-title">为你量身定制专业、高薪简历</div>
      <n-button class="link-btn" type="primary" @click="goTemplate">创建简历</n-button>
    </div>
    <MobileHomeFooter v-if="!companyConfig.hide_footer"/>
    <frFooter v-if="!companyConfig.hide_footer"/>
  </div>
</template>

<script lang="ts" setup>
import frHead from '@/components/head/head.vue'
import typeit from '@/components/typeit/typeit.vue'
import typeitAnswer from '@/components/typeit/typeitAnswer.vue'
import imgIcon from '@/components/imgIcon/imgIcon.vue'
import frFooter from '@/components/footer/footer.vue'
import MobileHomeFooter from './components/mobileHomeFooter.vue'
import { NButton, NCarousel, NCarouselItem } from 'naive-ui';
import homeMetaInfo from './homeMetaInfo'
import { useRouter } from 'vue-router'
import TypeIt from 'typeit'
import { ref, onMounted, computed } from 'vue';
import { useGlobalStore } from '@/store/useGlobalStore'

const globalStore = useGlobalStore();
  const companyConfig = computed(() => {
  return globalStore.companyConfig
})

defineOptions({
  metaInfo: homeMetaInfo
})

const textEl = ref<HTMLDivElement>();
onMounted(() => {
  const instance = new (TypeIt as any)(textEl.value, {
    strings: [
      "全新一代AI大模型简历生成工具",
      "轻松构建专业简历",
    ],
    cursorChar: "<span class='cursorChar'>|<span>",//用于光标的字符。HTML也可以
    waitUntilVisible: true,
    speed: 150,
    lifeLike: true,// 使打字速度不规则
    breakLines: false,// 控制是将多个字符串打印在彼此之上，还是删除这些字符串并相互替换
    loop: true,//是否循环
    nextStringDelay:[2000,500],
    loopDelay: [500,2000],
    deleteSpeed: 0,
    cursor: true,//在字符串末尾显示闪烁的光标
  })
  instance.go();
})

const router = useRouter()
const goTemplate = () => {
  router.push('/template-center');
}
const goHelper = () => {
  router.push('/interview-helper');
}
</script>

<style lang="scss" scoped>
.m-main {
  .banner {
    padding: 55px var(--m-content-padding) 9.5px;
    width: 100%;
    height: 320px;
    background: url('@/assets/img/banner.png') no-repeat center;
    background-size: cover;
    position: relative;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    color: #333;
    font-weight: 500;

    .txt {
      font-size: 16px;
      line-height: 22px;
      padding-left: 2px
    }

    .tit {
      margin-top: 16px;
      font-size: 26px;
      color: var(--primary);
      line-height: 35px;
    }

    .des {
      margin-top: 16px;
      font-size: 16px;
      line-height: 19px;
    }

    .link-btn {
      margin-top: 31px;
      height: 32px;
      font-size: 14px;
    }

    .logos {
      position: absolute;
      top: 100%;
    }

    .des2 {
      margin-top: 24px;
      font-weight: 400;
      font-size: 14px;
      line-height: 18.5px;
      color: #1A1A1A;
    }

    .group {
      margin-top: 19.5px;
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 400;

      .company-logo {
        width: auto;
        height: 10px;
        margin-right: 16px;
      }
    }
  }

  .tip-icon {
    text-align: center;
    margin-top: 125px;
    margin-bottom: 50px;
    font-size: 32px;
    color: var(--primary);
  }

  .block {
    padding: 0 var(--m-content-padding);

    .b-title {
      text-align: center;
      font-weight: 500;
      font-size: 18px;
      color: #1A1A1A;
      line-height: 24px;
    }
  }

  .my_resume {
    .card {
      margin-top: 18px;
      padding: 16.5px 6.5px;
      font-size: 12px;
      height: 246.5px;

      .resume-tit {
        display: flex;
        justify-content: space-between;
        font-weight: 500;
        font-size: 14px;
        color: #333;
        margin-bottom: 19.5px;
      }

      :deep(.msg) {
        font-size: 12px;
        padding-left: 9.5px;
        margin-bottom: 0px;

        &::after {
          top: 4px;
          width: 3px !important;
          height: 3px !important;
        }

        &+.msg {
          margin-top: 10.5px;
        }
      }
    }
  }

  .resume-template {
    padding-top: 40px;

    .template-ctx {
      margin-top: 28.5px;

      :deep(.n-carousel__dots) {
        bottom: 28px;

        .n-carousel__dot {
          width: 5px;
          height: 5px;
          border: 0.5px solid #ccc;

          &.n-carousel__dot--active {
            background-color: var(--primary);
            border-color: var(--primary);
          }
        }
      }
    }

    .carousel-img {
      margin: 0 auto;
      width: 100%;
      height: 100%;
      object-fit: cover;
      box-shadow: 0 1.5px 3px rgba(0, 0, 0, 0.16);
    }
  }

  .interview-assistant {
    position: relative;
    padding-top: 27px;
    background-color: #FAFAFD;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 80px;
      background-color: #fff;
      z-index: 1;
    }

    .card {
      position: relative;
      z-index: 2;
      background-color: #fff;
      margin-top: 19.5px;
      padding: 12px 8.5px;

      :deep(.box4_left_card) {
        width: 100%;
        padding: 14px;
        height: 190px;
        margin-bottom: 0;

        &.box4_left_card2 {
          height: 170px;
        }

        &+.box4_left_card {
          margin-top: 17.5px;
        }

        .ti-cursor {
          text-indent: 0px;
        }

        .line_one {
          font-size: 14px;
          align-items: center;

          .star {
            height: 18.5px;
            width: auto;
          }
        }

        .line_two {
          margin-top: 10px;

          .anser_text {
            width: 100%;
            padding-left: 0;
            text-indent: 2.5em;
            font-size: 12px;
            line-height: 20px;
            font-weight: 500;

            &::after {
              width: 15px;
              height: 15px;
              font-size: 10px;
              left: 4px;
              top: 0;
            }
          }
        }

      }
    }
  }

  .four-block {
    padding-top: 32px;

    .tip {
      font-size: 10px;
      line-height: 13.5px;
      margin-bottom: 3.5px;
      color: #FB7243;
      text-align: center;
      font-weight: 500;
    }

    .four-box {
      margin-top: 15px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 12.5px 11px;

      .box {
        position: relative;
        border: 0.5px solid #dedede;
        border-radius: 4px;
        box-shadow: 0 1.5px 3px rgba(0, 0, 0, 0.08);
        width: 100%;
        height: 190px;
        padding: 19px 11.5px;

        .icon {
          position: absolute;
          top: 16.5px;
          left: 10.5px;
          height: 10px;
          width: auto;
        }

        .txt {
          font-weight: 500;
          font-size: 10px;
          text-indent: 2em;
          line-height: 18px;
        }

        .partner {
          position: absolute;
          text-align: center;
          width: 100%;
          bottom: 10.5px;
          left: 0;

          .name {
            margin-top: 1.5px;
            font-size: 8px;
            left: 11px;

            .bold {
              font-weight: bold;
            }
          }
        }
      }
    }
  }

  .adv-box {
    padding-top: 48px;
    padding-bottom: 39px;
    text-align: center;

    .link-btn {
      margin-top: 13.5px;
    }
  }

  .link-btn {
    height: 22px;
    font-size: 10px;
    padding: 0 16px;
  }

  .card {
    border: 1px solid #dedede;
    border-radius: 8px;
    box-shadow: 0 1.5px 3px rgba(0, 0, 0, 0.08);
  }
}
</style>