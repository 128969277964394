import { createMenusOrder, getPayStatus, PAY_STATUS } from '@/api/pay'
import { Ref, ref, } from "vue";
import qrcode from 'qrcode'
import { useUserState } from '@/store/useUserStore';

interface TOrder {
  order_id: string
  url: string
  qrcode?: string
}

const MAX_TIME = 600000; // 10 * 60 * 1000 十分钟

export default function usePayOrder(menu: Ref<TVipMenu | undefined>) {
  const order = ref<TOrder>();
  const payStatus = ref<PAY_STATUS>(PAY_STATUS.INIT)
  const useStore = useUserState();

  let startTime: number;
  const initOrder = async () => {
    if (!menu.value) return;
    const { data } = await createMenusOrder({
      menu_id: menu.value.menu_id
    });
    if (data) {
      order.value = data
      qrcode.toDataURL(data.url, (err: any, url: string) => {
        if (err) return console.log(err);
        (order.value as TOrder).qrcode = url
      });
      // 开始轮询二维码结果
      startTime = Date.now()
      loopQrcodeResult()
    }
  }

  let timer: NodeJS.Timeout;
  const loopQrcodeResult = async (forced?: boolean) => {
    if (!order.value) return;

    clearTimeout(timer);

    // 超出最大轮询时间，终止轮询
    // 但是如果 forced === true 可强制刷新
    if (Date.now() - startTime >= MAX_TIME) {
      payStatus.value = PAY_STATUS.INVALID

      if (!forced) return;
    }

    const { data } = await getPayStatus({
      order_id: order.value.order_id,
      forced
    });
    if (data) {
      if (payStatus.value === PAY_STATUS.SUCCESS) {
        // 如果状态已经是成功，直接结束
        return;
      }

      payStatus.value = data.status;

      if (data.status === PAY_STATUS.INIT) { // 继续轮询
        timer = setTimeout(loopQrcodeResult, 3000); // 3s一次
        return;
      }
      if (data.status === PAY_STATUS.SUCCESS) {
        return;
      }
    }
  }

  return {
    initOrder,
    order,
    payStatus,
    stop: () => {
      // 关闭之后去更新用户信息，防止用户信息更新不及时
      useStore.getUserInfo()
      clearTimeout(timer);
    },
    forceUpdatePayStatus: () => {
      loopQrcodeResult(true)
    }
  }
}