<template lang="pug">
.box4_left_card
  .line_one 
    img(class='star' src="../../assets/img/star.png")
    | 问题：请简短地自我介绍。
  .line_two 
    .anser_text(ref="answer") 
.box4_left_card.box4_left_card2
  .line_one 
    img(class='star' src="../../assets/img/star.png")
    | 问题：如何确保cold call信息的真实性？
  .line_two
    .anser_text(ref="answer2" v-show="showAnswer2") 
</template>

<script setup>
import { ref, onMounted } from "vue";
import TypeIt from 'typeit'
const showAnswer2 = ref(false)
const answer = ref(null)
const answer2 = ref(null)
const typeitFunc = () => {
  new TypeIt(answer.value, {
    strings: [
      "您好，我是周媛媛。我在北京大学光华管理学院完成了我的本科和硕士学习，专业是工商管理。在学校，我主导了一项关于中小企业创新策略的研究，并与团队合作为京东集团完成了一个消费者行为分析项目。我还在麦肯锡和其他公司有过实习经验，积累了丰富的数据分析和项目管理经验。",
    ],
    cursorChar: "<span class='cursorChar'>|<span>",//用于光标的字符。HTML也可以
    speed: 30,
    lifeLike: true,// 使打字速度不规则
    breakLines: true,// 控制是将多个字符串打印在彼此之上，还是删除这些字符串并相互替换
    loop: false,//是否循环
    afterComplete: function (instance) {
      instance.destroy();
      showAnswer2.value = true
    }
  }).go()
  new TypeIt(answer2.value, {
    strings: [
      "在进行cold call时，我首先会确保自己的自我介绍和询问目的清晰明确，这有助于建立对方的信任。其次，我不会完全依赖单一来源的信息，而是会通过其他途径或与其他人员的交谈来验证和补充所获得的信息，确保其真实性和完整性。"
    ],
    cursorChar: "<span class='cursorChar'>|<span>",//用于光标的字符。HTML也可以
    speed: 30,
    startDelay: 7500,
    lifeLike: true,// 使打字速度不规则
    breakLines: true,// 控制是将多个字符串打印在彼此之上，还是删除这些字符串并相互替换
    loop: false,//是否循环
    afterComplete: function (instance) {
      instance.destroy();
    }
  }).go()
}
onMounted(() => {
  let options = {
    root: null,
    rootMargin: '0px',
    threshold: [0]
  }
  let showNums = 0
  let callback = function (entries, observer) {
    if (entries[0].intersectionRatio > 0 && showNums === 0) {
      showNums++
      typeitFunc()
    }
  };
  let typeItEl = document.querySelector(".answer_box");
  let observer = new IntersectionObserver(callback, options);
  observer.observe(typeItEl);
})
</script>

<style scoped lang="sass">
.box4_left_card
    width: 430px
    height: 214px
    background: #FAFAFD
    border-radius: 13px
    margin-bottom: 16px
    box-sizing: border-box
    padding: 18px
    .line_one
        display: flex
        align-items: flex-end
        font-size: 14px
        font-family: Microsoft YaHei-Bold, Microsoft YaHei
        font-weight: bold
        color: #333333
        line-height: 28px
        .star
            width: 33.77px
            height: 35.91px 
            margin-right: 8px
    .line_two
        margin-top: 7px
        position: relative
        .anser_text
            padding-left: 28px
            width: 400px
            height: 131px
            display: block
            font-size: 14px
            font-family: Microsoft YaHei-Regular, Microsoft YaHei
            font-weight: 400
            color: #333333
            line-height: 28px
            position: relative
            // text-indent: 28px
            &::after
                content: '答'
                display: block
                width: 24px
                height: 24px
                display: flex
                align-items: center
                justify-content: center
                background: #EFF0F4
                border-radius: 3px
                position: absolute
                left: 0px
                top: 2px
                color: #999999
                font-weight: 400
                text-indent: 0px
            .box4_left_card2
                width: 430px
                height: 192px
</style>
