<template lang="pug">
.head 
  .head_content
    .left_block
      router-link.logo(to="/")
        h1
          img(:src="companyConfig.black_logo_url")
        div {{ companyConfig.show_name }}
      .head_tabs.hide-m
        .head_tab(:class="{'active': activeRoute === '/my-resume'}" @click="goToView('/my-resume', true /* 需要登录 */)") 我的简历
        .head_tab(:class="{'active': activeRoute === '/template-center'}" @click="goToView('/template-center')") 简历模板
        .head_tab(:class="{'active': activeRoute === '/interview-helper'}" @click="goToView('/interview-helper')") 面试助手
        .head_tab(:class="{'active': activeRoute === '/interview'}" @click="goToView('/interview', true /* 需要登录 */)") 模拟面试
    .right_block
      .hide-m(v-if="userStore.token")
        VipBtn(v-if="userStore.isNormal" type="gold" @click="onOpenVipDialog") 会员限时优惠
        VipCardPopover(v-else)
          VipBtn(:type="userStore.isGroup ? 'primary' : 'gold'") {{ userStore.userTypeName }}
      UserMenuPopover(v-if="userStore.token")
        div
          .avatar-box.hide-m
            n-avatar(v-if="userInfo.avatar" round size="large" :src="userInfo.avatar")
            .default-avatar(v-else) {{ avatarName }}
            svg-icon(v-if="userStore.isGroup" class="vip-sign-icon" name="diamond")
            svg-icon(v-else-if="userStore.isVip" class="vip-sign-icon gold" name="diamond")
          .menu-icon.hide-pc
            svg-icon(name="menu")
      n-button( v-else class='login_btn' type="primary" @click="onOpenLogin") 登录
  LoginDialog
</template>

<script setup lang="ts">
import { useRouter, useRoute } from 'vue-router';
import { NButton, NAvatar } from 'naive-ui'
import { useUserState } from '@/store/useUserStore';
import { useGlobalStore } from '@/store/useGlobalStore';
import VipBtn from './vipBtn.vue';
import VipCardPopover from './vipCardPopover.vue';
import UserMenuPopover from './userMenuPopover.vue';
import LoginDialog from './loginDialog/index.vue'
import { computed } from 'vue';

const route = useRoute();
const activeRoute = computed(() => {
  return route.path
});


const userStore = useUserState();
const userInfo = computed(() => {
  return userStore.user_info
})
const avatarName = computed(() => {
  let name = userInfo.value.name || ''
  let len = name.length;
  return name.substring(len - 4);
})

const globalStore = useGlobalStore()

const companyConfig = computed(() => {
  return globalStore.companyConfig
})
const onOpenLogin = () => {
  globalStore.setShowLogin(true)
}
const onOpenVipDialog = () => {
  globalStore.setShowVipDialog(true)
}

const router = useRouter();
const goToView = (path: string, needLogin?: boolean) => {
  if (needLogin && !userStore.token) {
    globalStore.setAfterLoginPath(path);
    onOpenLogin()
    return;
  }

  router.push({ path: path });
}
</script>

<style scoped lang="sass">
.head
  position: fixed
  z-index: 99
  top: 0
  left: 0
  width: 100%
  min-width: var(--content-min-width)
  height: 80px
  background-color: #F6F8FF
  display: flex
  justify-content: center
  .head_content
    width: 100%
    max-width: var(--content-width)
    min-width: var(--content-min-width)
    padding: 0 var(--content-padding) 0 var(--content-padding)
    height: 100%
    display: flex
    justify-content: space-between
    align-items: center
    .left_block
      display: flex
      align-items: center
      .logo
        color: #333333
        font-size: 20px
        display: flex
        align-items: center
        cursor: pointer
        margin-right: 80px 
        img
          width: 27px
          height: 27px
          margin-right: 8px
      .head_tabs
        display: flex
        color: #333333
        font-size: 16px
        .head_tab
          cursor: pointer
          margin-right: 45px
          &:hover,
          &.active
            color: var(--primary)
    .right_block
      display: flex
      align-items: center
      .vip-btn
        margin-right: 48px
      .login_btn
        width: 120px
      .avatar-box
        position: relative
        cursor: pointer

        .default-avatar
          display: flex
          align-items: center
          justify-content: center
          background-color: var(--primary)
          color: #fff
          font-size: 12px
          width: 40px
          height: 40px
          border-radius: 50%
        .vip-sign-icon 
          position: absolute
          bottom: 0
          right: 0
          color: var(--primary)
          font-size: 15px

          &.gold
            color: #C36D21

// 移动端样式兼容
#app.m-view
  .head
    height: 55px
    background: #fff
    .logo
      font-size: 16px
      img
        width: 23px
        height: 23px
        margin-right: 4px
    .menu-icon
      font-size: 16px
    .login_btn
      width: 60px
      height: 22px
      font-size: 12px
</style>