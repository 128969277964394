import { lanhuTokenName, loginAllMsg } from '@/common/constants';

export const setLanhuToken = (token: string) => {
  localStorage.setItem(lanhuTokenName, token)
}

export const delLoginAllMsg = () => {
  localStorage.removeItem(loginAllMsg)
}

export const getLanhuToken = (): string => localStorage.getItem(lanhuTokenName) || '';

// 注册增加新的验证规则
export const checkRulePwd = (pwd: string) => {
  let len = 0;
  if (/\d/.test(pwd)) {
    len++;
  }
  if (/[A-Z]/.test(pwd)) {
    len++;
  }
  if (/[a-z]/.test(pwd)) {
    len++;
  }
  if (/[^A-Za-z0-9\s]/.test(pwd)) {
    len++;
  }

  if (len < 3) return false
  return true
}
// eslint-disable-next-line complexity
export const pwdStrength = (pwd: string) => {
  let pwdLength = 0;
  let hasNumber = 0;
  let hasLetter = 0;
  let hasSymbol = 0;

  let numberLength = pwd.replace(/[^0-9]+/g, '');
  let smallLetterLength = pwd.replace(/[^a-z]+/g, '');
  let bigLetterLength = pwd.replace(/[^A-Z]+/g, '');
  let symbolLength = pwd.replace(/[a-zA-Z0-9 ]/g, '');
  // 密码长度规则
  if (pwd.length <= 4) {
    pwdLength = 0;
  } else if (pwd.length <= 10 && pwd.length >= 5) {
    pwdLength = 1;
  } else if (pwd.length >= 11) {
    pwdLength = 2;
  }
  // 数字个数
  if (numberLength) {
    if (numberLength.length === 0) {
      hasNumber = 0;
    } else if (numberLength.length === 1) {
      hasNumber = 1;
    } else if (numberLength.length > 1) {
      hasNumber = 2;
    }
  }
  // 字母个数
  if (smallLetterLength || bigLetterLength) {
    if (smallLetterLength.length > 0 && bigLetterLength.length === 0) {
      hasLetter = 1;
    } else if (bigLetterLength.length > 0 && smallLetterLength.length === 0) {
      hasLetter = 1;
    } else if (smallLetterLength.length > 0 && bigLetterLength.length > 0) {
      hasLetter = 2;
    }
  }
  // 符号个数
  if (symbolLength) {
    if (symbolLength.length === 0) {
      hasSymbol = 0;
    } else if (symbolLength.length === 1) {
      hasSymbol = 1;
    } else if (symbolLength.length > 1) {
      hasSymbol = 2;
    }
  }
  const strength = pwdLength + hasNumber + hasLetter + hasSymbol;

  if (strength > 0 && strength <= 3) {
    return {
      level: 'low',
      score: strength,
      msg: '密码强度较弱，建议输入字母、数字、符号的组合哦'
    }
  } else if (strength >= 4 && strength <= 6) {
    return {
      level: 'medium',
      score: strength,
      msg: '密码强度中等，建议输入字母、数字、符号的组合哦'
    }
  } else if (strength > 7) {
    return {
      level: 'high',
      score: strength,
      msg: '密码强度不错哦'
    }
  }
}

// 清除为空的参数、清除字符串的前后空格
export function formatParameter(params: Record<string, any>, method = 'get') {
  for (let key in params) {
    if (
      params[key] === undefined ||
      params[key] === null ||
      (params[key] === '' && method.toLowerCase() === 'get')
    ) {
      delete params[key]
    } else if (typeof params[key] === 'string') {
      params[key] = params[key].replace(/(^\s*)|(\s*$)/g, '')
    }
  }
}

// 创建formdata格式的数据
export function createFormData(data: Record<string, any>) {
  if (data instanceof FormData) return data;
  const formData = new FormData();
  for (let k in data) {
    if (data.hasOwnProperty(k)) {
      // FormData仅支持基本类型数据，引用类型的会调用其toString方法，所以要单独处理
      const item = data[k]
      if (Array.isArray(item)) {
        item.forEach(v => {
          formData.append(k, v)
        })
      } else {
        formData.append(k, data[k])
      }
    }
  }
  return formData;
}

export function alinkDownload({ url, name }: { url: string, name?: string }) {
  const downA = document.createElement('a');
  downA.href = url;
  downA.download = name || '';
  downA.click();
}

// 获取指定参数的值
export function getQueryParam(name: string, query: string) {
  if (!query || typeof query !== 'string') {
    return null
  }
  let queryStartIndex = query.indexOf("?") + 1;
  let param = query.substring(queryStartIndex);

  let vars = param.split("&");
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split("=");
    if (pair[0] === name) {
      return decodeURIComponent(pair[1]);
    }
  }

  return null;
}

// 日期格式化
export function format(data: Date | string | number, pat: string) {
  if (!(data instanceof Date)) {
    if (typeof data === 'string') {
      let str = data;
      data = new Date(str.replace(/-/g, '/'));
      if (data.toString() === 'Invalid Date') {
        return str
      }
    } else if (typeof data === 'number') {
      data = new Date(data);
    } else {
      return '';
    }
  }

  var year = data.getFullYear();
  var month = data.getMonth() + 1;
  var day = data.getDate();
  var hour = data.getHours();
  var minute = data.getMinutes();
  var second = data.getSeconds();

  // 两位补齐
  var autocomplete = function (int: number) {
    if (isNaN(int)) {
      int = 0;
    }
    return (int > 9 ? '' : '0') + int
  };

  if (!pat) {
    pat = 'YYYY-MM-DD';
  }

  pat = pat.replace(/YYYY/g, String(year));
  pat = pat.replace(/yyyy/g, String(year));
  pat = pat.replace(/MM/g, autocomplete(month));
  pat = pat.replace(/DD/g, autocomplete(day));
  pat = pat.replace(/hh/gi, autocomplete(hour));
  pat = pat.replace(/mm/g, autocomplete(minute));
  pat = pat.replace(/ss/g, autocomplete(second));

  return pat;
}

export function copyText(text: string) {
  return navigator.clipboard.writeText(text)
}

export function isMobileView() {
  return window.screen.width <= 500
}