/* eslint-disable no-unused-vars */
export enum HttpStatus {
    SUCCESS = 200,
    RELOGIN = 420
}

export enum CodeStatus {
    SUCCESS = 0,
}

interface ICODE_MSG {
    [key: string]: string;
}

export const HttpMsg: ICODE_MSG = {
    '401': '登录状态失效，请重新登录',
    '401-reset': '重置链接已失效，请重新获取',
    '416': '服务异常',
    '417': '服务时间已过期',
    '419': '此设备上的登录状态已失效',

}

// 
export const RedirectList: ICODE_MSG = { 
    'emp': '#/member'
}

// 错误信息自定义
export const CodeMsg: ICODE_MSG = {
    /** 通用错误 */
    '10003': '该邮箱已注册了',
    '10025': '密码格式不正确',
    '10036': '请点下方【忘记密码】激活账户',
    '10045': '请点下方【忘记密码】激活账户',
    '10046': '该邮箱已注册了',
    '500': '服务器异常',
    '10001': '邮箱输入错误',
    '10007': '没有相关项目',
    '10093': '授权登陆失败',
    '10106': '密码错误次数过多，请点击忘记密码修改后重试',
    '10072': '文件夹不为空，无法删除~',
    '12013': '邮箱不符合规范',
    '10002': '邮箱或密码错误',
    '10006': '没有相关团队',
    '10069': '登录太多次，请稍后重试',
    '10021': '该邮箱已经被注册',
    '13001': '您没有该操作的权限',
    '13002': '根据角色限定，您没有该操作的权限',
    '13003': '权限错误',
    '13004': '请在团队信息页面进行团队超级管理员变更操作！',
    '13005': '操作失败，请手动刷新页面后重试',
    '13006': '登录已过期，请重新登录',
    '13007': '操作失败，请重试',
    '13008': '编辑团队超级管理员为他人后，方可移除该用户',
    '13009': '该操作将使您登出管理后台，请谨慎实施。详情请咨询对应客户成功经理',
    '13010': '没有相关部门',
    '13011': '添加失败，企业管理员数量已达上限',
    '13012': '无此操作权限', // 当前用户等于要删除的用户,
    '13013': '操作失败，不可指定此角色',
    '13014': '操作失败，不可指定此部门',
    '13016': '添加失败，部门管理员数量已达上限',
    '13017': '操作失败，请手动刷新页面后重试',
    '13018': '操作失败，请手动刷新页面后重试',
    '13019': '解散失败，请将团队内项目资产妥善处理后方可解散',
    '13022': '部门已存在',
    '13031': '项目删除失败',
    '13020': '操作失败，已达licence上限',
    '13021': '操作失败，请手动刷新页面后重试',
    '13024': '邮箱不符合系统规范',
    '13025': '操作失败，请手动刷新页面后重试',
    '13026': '该邮箱后缀已存在',
    '13027': '内容长度已超过系统上限',
    '13029': '内容长度已超过系统上限',
    '13030': '编辑团队超级管理员为他人后，方可移除该用户',
    '13036': '请勿重复添加',
    '13065': '没有找到文件夹',
    '13066': '权限冲突，请稍后再试',
    '13067': '所有者不能被移除',
    '13068': '您没有文件的编辑权限',
    '13069': '您没有文件夹的编辑权限',
    '13070': '资源删除失败',
    '13071': '当前文件夹不是根文件夹',
    '13072': '没有找到文件',
    '13073': '文件无法恢复',
    '13074': '文件夹删除错误',
    '13075': '文件夹粉碎错误',
    '13076': '该用户没有文件夹权限',
    '13077': '该用户没有文件权限',
    '13078': '修改保存失败，请重新修改',
    '13079': '文件夹恢复错误',
    '13080': '分享链接错误',
    '13081': '您没有访问权限 ',
    '13082': '文件权限发生变化，请访问新链接',
    '13083': '请先绑定项目后，再分享',
    '13085': '文件不存在',
    '13086': '资源不存在',
    '13087': '分享链接复制失败，请稍后再试',
    '13088': '链接过期，请访问新链接',
    '13089': '访问密码错误',
    '13090': '分享邀请不需要密码',
    '13091': '请选择链接有限期',
    '13092': '权限发生变化，请访问新链接',
    '13093': '当前不支持分享',
    '13094': '分享时传入的参数有误',
    '13095': '您没有权限访问',
    '13096': '文件夹为空',
    '13098': '操作频繁，请稍后再试',
    '13099': '操作频繁，请稍后再试',
    '13104': '名称不能为空',
    '13105': '文件夹未绑定项目',
    '13106': '文件权限发生变化，请访问新链接',
    '13107': '不支持被分享',
    '13108': '文件过大不支持上传',
    '13109': '文件格式不支持上传',
    '13210': '更新权限错误',
    '13214': '文件格式不同，请重新选择',
    '13218': '系统内存不足，请稍后重试！',
    '10071': '没有该文件夹',
    '10097': '您没有文件的下载权限',
    '13055': '本审批环节已经结束',
    '13056': '不是正确的处理节点，请联系蓝湖技术支持',
    '13059': '审批理由字数超出上限',
    '13057': '请刷新重试',
    '13216': '您的账号已被禁用，如有问题请联系系统管理员',
    '10075': '用户无权限'
}