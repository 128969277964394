import router from './index'
import { RouteLocationNormalized, NavigationGuardNext } from "vue-router";
import checkVersion from '@/utils/checkVersion'
import { useUserState } from '@/store/useUserStore';
/**
 * 前提：通过预渲染插件生成了/template-center/index.html的页面
 * 问题：在路径/template-center刷新时，后台会自动重定向到/template-center/
 *      所以在白名单中补充/template-center/的路径
 */
const whiteList = ['/', '/group-login', '/token-login', '/m-login', '/user-privacy-terms', '/service-agreement', '/template-center', '/template-center/', '/interview-helper', '/create-resume', '/about-us']
router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const userStore = useUserState();
  if (userStore.token) {
    if (userStore.user_info.uid) {
      next();
    } else {
      // 刷新页面进入，同时也刷新用户信息，
      await userStore.getUserInfo()
      next();
    }
  } else {
    if (whiteList.includes(to.path)) {
      next();
    } else {
      next({ path: '/' })
    }
  }
  checkVersion();
  setTimeout(() => {
    window.scrollTo({ top: 0 })
  })
});