import { createApp } from 'vue'
import '@/assets/style/index.scss'
import router from '@/router/index'
import '@/router/beforeEach'
import App from './App.vue'
import createStore from './store/index';
import 'virtual:svg-icons-register';
import SvgIcon from "./components/SvgIcon.vue";
import initMode from "./init/index";
import MetaInfo from 'vue-meta-info';
import { initCompanyConfig } from './api/common'
initMode();

const store = createStore();

initCompanyConfig();

const app = createApp(App)
app.use(store)
  .use(router)
  .use(MetaInfo)
  .component('svg-icon', SvgIcon)
  .mount('#app')