<template lang="pug">
#app(:class="{'m-view': isMobile}")
  n-config-provider(:theme-overrides="themeOverrides" :locale="zhCN" :date-locale="dateZhCN")
    n-dialog-provider
      n-message-provider(:duration="4000")
        router-view
        vipDialog
</template>
<script lang="ts" setup>
import { NConfigProvider, NMessageProvider, NDialogProvider, zhCN, dateZhCN } from 'naive-ui'
import type { GlobalThemeOverrides } from 'naive-ui'
import vipDialog from '@/components/vipDialog/index.vue'
import { isMobileView } from '@/utils/index'

const isMobile = isMobileView();
// // 配置 naive ui 全局样式
const themeOverrides: GlobalThemeOverrides = {
  common: {
    primaryColor: 'rgb(56, 96, 226)',
    primaryColorHover: 'rgb(116, 144, 235)',
    primaryColorPressed: 'rgb(45, 77, 181)',
    successColor: 'rgb(81, 171, 69)',
    errorColor: 'rgb(253, 104, 122)',
    textColorBase: '#333',
    textColor1: '#333',
    textColor2: '#333',
    textColor3: '#333',
  },
  Button: {
    borderRadiusMedium: isMobile ? '5px' : '8px',
    borderRadiusLarge: isMobile ? '5px' : '8px',
    borderRadiusSmall: isMobile ? '5px' : '8px',
  },
  // tabTextColorActiveLine: '#3860E2',
}
</script>