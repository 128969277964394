import { getStorage, setStorage } from "./storage"

let version = getStorage('_version');
export default async function () {
  const res = await fetch('/version.json?t=' + Date.now());
  const data = await res.json();
  const newVersion = data.version;
  setStorage('_version', newVersion);

  if (version && version !== newVersion) {
    window.location.reload();
  } else {
    version = newVersion;
  }
}
