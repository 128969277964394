<template>
  <n-tooltip v-if="tooltip" effect="dark" :placement="placement">
    <template #trigger>
      <svg class="svg-icon" :class="svgClass" aria-hidden="true" @click="onClick" @mouseenter="emit('mouseenter', $event)"
        @mouseleave="emit('mouseleave', $event)">
        <use :xlink:href="iconName" />
      </svg>
    </template>
    {{ tooltip }}
  </n-tooltip>
  <svg v-else class="svg-icon" :class="svgClass" aria-hidden="true" @click="onClick"
    @mouseenter="emit('mouseenter', $event)" @mouseleave="emit('mouseleave', $event)">
    <use :xlink:href="iconName" />
  </svg>
</template>

<script lang="ts" setup>
import { NTooltip, PopoverPlacement } from 'naive-ui';
import { computed } from 'vue';

const props = defineProps<{
  name: string
  className?: string
  tooltip?: string
  placement?: PopoverPlacement
  disabled?: boolean
}>();
const emit = defineEmits<{
  (e: 'click', event: Event): void
  (e: 'mouseenter', event: Event): void
  (e: 'mouseleave', event: Event): void
}>()
const iconName = computed(() => `#icon-${props.name}`);
const svgClass = computed(() => {
  let className = '';
  if (props.className) {
    className += props.className;
  }
  if (props.disabled) {
    className += ' is-disabled';
  }
  return className;
});

const onClick = (e: Event) => {
  if (props.disabled) return;
  emit('click', e);
};
</script>

<style scoped>
.svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.svg-icon.is-disabled {
  cursor: not-allowed !important;
}

.svg-external-icon {
  background-color: currentColor;
  mask-size: cover !important;
  display: inline-block;
}
</style>