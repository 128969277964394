const Storage = window.localStorage;

export const getStorage = <T>(keyName: string): T | null => {
  const resStr = Storage.getItem(keyName);
  if (resStr) {
    return JSON.parse(resStr) as T
  } else {
    return resStr as T | null;
  }
}

export const setStorage = (keyName: string, data: any) => {
  if (data === undefined || data === null) {
    removeStorage(keyName)
  } else {
    Storage.setItem(keyName, JSON.stringify(data));
  }
}

export const removeStorage = (keyName: string) => {
  Storage.removeItem(keyName);
}