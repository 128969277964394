<template>
  <footer class="home-footer">
    <div class="footer-content">
      <div class="brand">
        <div class="flex">
          <img :src="companyConfig.black_logo_url" alt="" class="logo">
          <div class="brand-name">{{ companyConfig.show_name }}</div>
        </div>
        <div class="des">全新一代AI大模型简历生成工具</div>
      </div>

      <n-collapse class="collapse" arrow-placement="right">
        <n-collapse-item class="menu-ul" title="产品列表" name="1">
          <a class="li" href="/my-resume" target="blank">AI优化简历</a>
          <a class="li" href="/template-center" target="blank">职业模板</a>
          <a class="li" href="/template-center?type=school" target="blank">高校模板</a>
          <a class="li" href="/interview-helper" target="blank">面试助手</a>
        </n-collapse-item>
        <n-collapse-item class="menu-ul" title="关于我们" name="2">
          <a class="li" href="/about-us" target="blank">公司介绍</a>
          <a class="li" href="/service-agreement" target="blank">用户协议</a>
          <a class="li" href="/user-privacy-terms" target="blank">隐私协议</a>
          <!-- todo: 更新了《用户增值服务协议》之后，把链接替换上 -->
          <a class="li" href="/" target="blank">用户增值服务协议</a>
        </n-collapse-item>
      </n-collapse>

      <div class="qrcode-box">
        <div class="qrcode">
          <img src="@/assets/img/kefu.png" alt="" class="img">
          客服
        </div>

        <div class="qrcode">
          <img src="@/assets/img/gongzhonghao.jpg" alt="" class="img">
          未来简历
        </div>
      </div>

      <div class="connect">
        <div class="tit">联系方式</div>
        <div class="text">商务合作：13051181982</div>
        <div class="text">公司地址：北京市海淀区中关村南大街乙12号院</div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts" setup>
import { NCollapse, NCollapseItem } from 'naive-ui';
import { useGlobalStore } from '@/store/useGlobalStore';
import { computed } from 'vue';

const globalStore = useGlobalStore();
  const companyConfig = computed(() => {
  return globalStore.companyConfig
})
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
  align-items: center;
}

.home-footer {
  width: 100%;
  color: #1A1A1A;
  padding: 0 var(--content-padding);

  .footer-content {
    border-top: 0.5px solid #E6E6E6;
    padding: 27.5px 0 16px;

    .brand {
      .flex {
        align-items: flex-end;
      }

      .logo {
        width: 28.5px;
        height: auto;
        margin-right: 6.5px;
      }

      .brand-name {
        font-weight: bold;
        font-size: 16px;
      }

      .des {
        font-weight: 500;
        font-size: 10px;
        margin-top: 6px;
      }
    }

    .collapse {
      margin-top: 24px;

      .menu-ul {
        &+.menu-ul {
          margin-top: 8px;
          border-top: none;

          :deep(.n-collapse-item__header) {
            padding-top: 8px;
          }
        }

        :deep(.n-collapse-item__header-main) {
          justify-content: space-between;
          font-weight: 500;
          font-size: 12px;
        }

        .ul-t {
          font-weight: bold;
          font-size: 16px;
          margin-bottom: 20px;
          line-height: 22px;
        }

        .li {
          display: block;
          font-size: 12px;
          line-height: 16px;
          color: #1A1A1A;

          &:not(.text):hover {
            color: var(--primary);
          }

          &+.li {
            margin-top: 8px;
          }
        }
      }
    }

    .qrcode-box {
      margin-top: 48px;
      display: flex;
      justify-content: center;
    }

    .qrcode {
      text-align: center;
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;

      &+.qrcode {
        margin-left: 28px;
      }

      .img {
        display: block;
        width: 87px;
        height: 87px;
        margin-bottom: 2.5px;
      }
    }

    .connect {
      margin-top: 28.5px;
      text-align: center;

      .tit {
        font-size: 14px;
        font-weight: bold;
        line-height: 18.5px;
      }

      .text {
        font-size: 12px;
        font-weight: 500;
        margin-top: 11px;
        line-height: 16px;
      }
    }
  }
}
</style>